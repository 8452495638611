<template>
  <div>
    <!-- v-on:click="recopila" -->
     <!-- v-on='on' -->
    <v-dialog v-model="dialog_activator" persistent content-class="modal">

      <template v-slot:activator="{}">
        <v-btn
         v-on:click="activa()"
         v-bind="$cfg.btn.busca_with_text" >
          Control de cambios ({{Ctcc.value>""?Ctcc.value.split("$").length:"0"}})
        </v-btn>

      </template>

      <!-- <v-dialog content-class="modal" persintent v-model="modal">
        <template> -->
      <div class="contenedor centrado">
        <v-sheet :elevation="4">
          <base_Header
          :Entorno="Entorno"
          @onEvent="$emit('close')"
          >

     
           
          </base_Header>
          <div class="conflex">
            <div v-if="estado=='editar'" class="columna contenedor" style="width:250px">
              <div>Indique el motivo de la modificaciC3n</div>
              <v-radio-group v-model="ncaso"
                @change="get_valueaux">
                <v-radio
                  v-for="(caso, index) in casos"
                  :key="index"
                  :label="caso"
                  :value="index+1"
                
                ></v-radio>
              </v-radio-group>
            </div>

            <div  class="columna contenedor"  style="width:450px">
              <div style="padding-top:10px"> </div>
              <div  v-for="(change, index) in changes"  :key="index">
                <div> {{change}} </div>
              </div>
              <!-- <v-textarea heigth="500px"></v-textarea> -->
            </div>
           </div>
            <div v-if="estado=='editar'">
               <v-text-field 
                style="padding:5px;width:450px"
                v-bind="$input"
                v-model="comentario"
                label="Comentario modificaciC3n"
              @change="get_valueaux">
              </v-text-field>
              </div>
           
        </v-sheet>
      </div>
      <!-- </template>
      </v-dialog> -->
    </v-dialog>
  </div>
</template>

<script>
import plugs from "@/common/general_plugs";
import { mixinCtrls } from "@/mixins/mixinCtrls";
const base_Header = () => plugs.groute("base_Header.vue", "base");
export default {
   mixins: [mixinCtrls],
  components: { base_Header },
  props: {
    // Entorno
   casos: { type: Array, default: function () { return [] } },
   Ctcc: { type: Object, default: function () { return {} } },
   //maxchanges: Number,
    //maxcoments: Number,
    estado: {type:String, default:''},
    dialog: {type: Boolean, default: false}
    //estado: {type:String, default:''}
  },
  created() {
    this.ini_data();
  },
  data() {
    return {
      ncaso:0,
      seleccion:0,
      comentario: "",
      changes:[],
      Entorno: {},
    };
  },
  computed:{
      dialog_activator:{
       get: function() {
             return this.dialog
       },
       set: function(val) {
         return val
     }
  }},
  methods: {
    activa(){
      console.log("dialog:=",this.dialog)
      this.dialog?this.$emit("close"):this.$emit("open")
      // if (!this.dialog){
      //   alert("open")
      //   this.$emit("open")
      // } else if 
      // (this.dialog){
      //   alert("close")
      //    this.$emit("close");  
      // } 

    },

    ini_data() {
      console.log("ini-data")
      //Pendiente crear una entrada para cabecera aislada y quitar la de los Base
      this.Entorno = JSON.parse(JSON.stringify(this.$cfg.base.F.header));
      this.Entorno.titulo = "Control de cambios";
    },

    get_valueaux(nuevo=0){
      if (nuevo==99) {
       // alert("nuevo")
        this.Ctcc.comp.valueaux=this.tccId()+";"+99+";";
        console.log (this.Ctcc.comp.valueaux)
        return
      }
     if(this.ncaso==90){
       this.Ctcc.comp.valueaux = ''
     } else {
        this.Ctcc.comp.valueaux=this.tccId()+";"+this.ncaso+";"+this.comentario
        if (this.Ctcc.value>"") this.Ctcc.comp.valueaux+="$";
        console.log(this.Ctcc.comp.valueaux)
     }
    },

    tccId() {
      let fh = new Date();
      let tcc = `${fh.getFullYear()}${String(fh.getMonth() + 1).padStart(2,"0")}${String(fh.getDate()).padStart(2, "0")}`;
      tcc += `${String(fh.getHours()).padStart(2, "0")}${String(fh.getMinutes()).padStart(2, "0")};`;
      console.log(this.usu)
      tcc += this.usu.substring(0, 10);
      return tcc;
    },

    recopila() {
        // inicializo la seleccion de los radio cuando entra si no ha introducido nada
        // utilizo ncaso=90 por ejemplo, para que no marque ningun radio
        if (this.Ctcc.comp.valueaux==""){
                this.ncaso=90
                this.comentario=''
        }
        // Recopilo datos 
         if (!this.Ctcc.value) {
           this.changes='';
           return;
         }
         this.changes=this.Ctcc.value.split("$").map(item => {
                     let cut = item.split(";");
                      if (cut[2]>0 && cut[2]<=this.casos.length) cut[2]=this.casos[cut[2]-1];
                      if (cut[2]==90) cut[2]='';
                      if (cut[2]==99) cut[2]='ALTA';
                      return cut[0].substr(6, 2)+"-"+cut[0].substr(4, 2)+"-"+cut[0].substr(0, 4)+" "+cut[0].substr(8, 2)+":"+cut[0].substr(10, 2)+" "+
                               cut[1]+" "+cut[2]+" "+cut[3]
     });

      },
  },
      watch: {
    // los cambios de alta los hace automaticamente cundo detecta que hay un alta
      estado(newValue){
              if (newValue=='nuevo'){
              this.ncaso=99
              this.get_valueaux(99)
              }
     },
     dialog(newValue){
       console.log("recopila dialog_activ: ", newValue)
       if (newValue) this.recopila();
     }

    }
  
};
  



</script>

